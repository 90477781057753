.consent-collapsible {
  cursor: pointer;
}
.consent-collapsible:nth-child(n+2) {
  margin-top: 10px;
}
.consent-collapsible:before {
  content: '\25B8  ';
}
.consent-collapsible.is-active:before {
  content: '\25BE  ';
}
.consent-content {
  display: block;
  overflow: hidden;
  line-height: 1.65;
}

.consent {
  font-size: 14px;
}

.consent h4, .consent h6{
  font-weight: bold;
}

