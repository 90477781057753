@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@300;500&display=swap');
@import '@reach/tooltip/styles.css';
@import 'cropperjs/dist/cropper.css';
@import 'swiper/css';
@import './styles/consent.css';
@import './styles/crop.css';
@import './styles/scroll.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sarabun', sans-serif;
  overflow: hidden;
}

.scrollbar-none {
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none !important;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none !important;
}

.scrollbar-none::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  /* Hide arrow on input type number for Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  /* Hide arrow on input type number for Firefox */
  -moz-appearance: textfield;
}

/* The radio custom */
.radio-custom {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border-color: #eeeeee;
  border-width: 1px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-custom .check-mark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-custom input:checked ~ .check-mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-custom .check-mark:after {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

.check-dot {
  background-color: #3f54d9;
  box-shadow: inset 0 0 0 5px white;
}

.break-word {
  word-break: break-word;
}

.border-custom {
  background-color: #f4f4f7;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23858BAFFF' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
}

.loader {
  border-top-color: #eeeeee;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.challenge th {
  border-left: none;
  border-right: none;
  border-top: 1px solid #eee;
  vertical-align: middle;
}

.challenge tr {
  vertical-align: top;
}

.react-datepicker-popper {
  z-index: 2;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
