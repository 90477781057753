::-webkit-scrollbar {
    background-color: #eeeeee;
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #3f54d9;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 2px solid #eee;
}

.scrollbar-none {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}
  
.scrollbar-none::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}