/* tiptap css */
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror {
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 300;
  min-height: 300px;
}

.ProseMirror blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 30px;
  border-left: 3px solid #eee;
}

.ProseMirror ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.ProseMirror li {
  display: list-item;
}
.ProseMirror li p {
  text-indent: 0 !important;
}
.ProseMirror ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.ProseMirror mark {
  color: inherit;
}

.ProseMirror a {
  color: #3f54d9;
  cursor: pointer;
  text-decoration: underline;
}

.tiptap-edit .ProseMirror {
  height: 200px;
  overflow-y: scroll;
}

.tiptap-edit .ProseMirror p {
  text-indent: 10%;
}

.tiptap-edit .ProseMirror li * {
  text-align: left !important;
  text-indent: 0 !important;
}

.tiptap-edit .ProseMirror p[style*='text-align: center'],
.tiptap-edit .ProseMirror p[style*='text-align: right'],
.tiptap-edit .ProseMirror p[style*='text-align: justify'] {
  text-indent: 0;
}

.tiptap-edit .ProseMirror p img {
  max-width: 80%;
}

.tiptap-edit .ProseMirror::-webkit-scrollbar {
  background-color: #eeeeee;
  width: 8px;
  height: 8px;
}


.ProseMirror img {
  display: inline-block;
}

.ProseMirror mark {
  color: inherit;
}

.ProseMirror.ProseMirror-focused {
  outline: none;
}

.tiptap-edit .ProseMirror img[alt~='sticker'] {
  width: 100px;
  height: 100px;
  vertical-align: initial;
}

.ProseMirror strong {
  font-weight: 700;
}


/* spoiler */
.spoiler-container {
  border: 1px solid #0d0d0d20;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}

.spoiler-container .title {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
}

.spoiler-container .content {
  padding: 0.5rem;
  border-top: 1px solid #0d0d0d20;
}

/* iframe */
.iframe-wrapper {
  position: relative;
  padding-bottom: 400px;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.iframe-wrapper .ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* footnote */
.toc {
  background: rgba(black, 0.1);
  border-radius: 0.5rem;
  opacity: 0.75;
  padding: 0.75rem;
}

/* show placeholder */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror .is-empty.is-editor-empty {
  font-size: 14px;
}

/* tiptap-viewer */
.tiptap-viewer .ProseMirror {
  border: none;
  padding: 0px;
}

.tiptap-viewer .ProseMirror p {
  text-indent: 10%;
}

.tiptap-viewer .ProseMirror p[style*='text-align: center'],
.tiptap-viewer .ProseMirror p[style*='text-align: right'],
.tiptap-viewer .ProseMirror p[style*='text-align: justify'] {
  text-indent: 0;
}

.tiptap-viewer .ProseMirror p img {
  max-width: 80%;
}

.tiptap-viewer .ProseMirror img[alt~='sticker'] {
  width: 100px;
  height: 100px;
  vertical-align: initial;
}

.tiptap-viewer.night-mode p,
.tiptap-viewer.night-mode span,
.tiptap-viewer.night-mode div,
.tiptap-viewer.night-mode a {
  color: #ffffff !important;
}

.tiptap-viewer.sepia-mode p,
.tiptap-viewer.sepia-mode span,
.tiptap-viewer.sepia-mode div,
.tiptap-viewer.sepia-mode a {
  color: #0c1135 !important;
}

.tiptap-viewer.dark-mode p,
.tiptap-viewer.dark-mode span,
.tiptap-viewer.dark-mode div,
.tiptap-viewer.dark-mode a {
  color: #767676 !important;
}

.ProseMirror p:before {
  content: ' ';
 }
 .ProseMirror p:not(.is-editor-empty) .ProseMirror-trailingBreak {
   content: ' '; margin-top: 0; margin-bottom: 0;
 }
 